<template>
    <div style="display: flex;flex-direction: column;">
        <div style="color: #fff;font-size: 0.3rem;border: 1px solid #fff;">全检</div>
        <dv-scroll-board :config="quanJianConfig" style="width:100%;height:5rem;" />
    </div>
</template>
<script>
export default {
    props: {
        quanJianConfig: {

        }
    },
    data() {
        return {

        }
    }, created() {
    }
}
</script>
<style>
.header-item{
    font-size: 0.2rem;
}
.ceil{
    font-size: 0.16rem;
}
</style>