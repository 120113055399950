<template>
    <div style="display: flex;flex-direction: column;">
        <div style="color: #fff;font-size: 0.3rem;border: 1px solid #fff;">编织</div>
        <dv-scroll-board :config="bianZhiConfig" style="width:100%;height:5rem;" />
    </div>
</template>
<script>
export default {
    props: {
        bianZhiConfig: {

        }
    },
    data() {
        return {

        }
    }, created() {
    }
}
</script>
<style></style>