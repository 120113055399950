<template>
  <div style="display: flex;flex-direction: column;">
    <div style="color: #fff;font-size: 0.3rem;border: 1px solid #fff;">配袜</div>
    <dv-scroll-board :config="peiWaConfig" style="width:100%;height:5.5rem;" />
  </div>
</template>
<script>
export default {
  props: {
    peiWaConfig: {

    }
  },
  data() {
    return {

    }
  }, created() {
  }
}
</script>
<style></style>
