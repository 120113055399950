<template>
  <dv-full-screen-container class="da_pin_container" allowfullscreen="true">
    <dv-loading v-if="showLoaing" style="color:#fff">数据加载中...</dv-loading>
    <div class="date_class">
      <div style="width: 30%;color: #fff;display: flex;justify-content: space-around;align-items: center;">
        <div>
          当前生产线：{{ currentShengChanXian }}
        </div>
        <div style="display: flex;justify-content: center;align-items: center;">当前刷新频率：{{
            getShuaXinLabel(currentShuaXinPinLv)
          }}
        </div>
      </div>
      <div style="width: 40%;font-size: 0.4rem;">
        <span>日期：{{ showDate() }}</span>
        <span style="font-size: .2rem;margin-left: .1rem">
          {{tipLoading==true?'刷新中...':''}}
        </span>
        <div style="margin-left: 0.04rem;color: #fff;" customClass element-loading-text="刷新中..."
             v-loading="tipLoading"
             element-loading-spinner="el-icon-loading" element-loading-background="#3D3D57"></div>
      </div>
      <div style="display: flex;align-items: center;width: 30%;">
        <span style="font-size: 0.2rem;margin-left: 1.5rem;">生产线：</span>
        <!--        <el-select v-model="currentShengChanXian" size="mini" style="width: 1rem;" @change="selectHaoXian">-->
        <!--          <el-option v-for="item, index in shengChanXianList" :label="item" :value="item" :key="index"></el-option>-->
        <!--        </el-select>-->
        <select v-model="currentShengChanXian" @change="selectHaoXian">
          <option v-for="(item,index) in shengChanXianList" :key="index" :label="item" :value="item"></option>
        </select>
        <span style="font-size: 0.2rem;margin-left: 1.5rem;">刷新频率：</span>
        <!--        <el-select v-model="currentShuaXinPinLv" size="mini" style="width: 1rem;" @change="selectShuaXinPinLv">-->
        <!--          <el-option v-for="item, index in shuaXinPinLv" :label="item.label" :value="item.id" :key="index"></el-option>-->
        <!--        </el-select>-->
        <select v-model="currentShuaXinPinLv" @change="selectShuaXinPinLv">
          <option v-for="(item,index) in shuaXinPinLv" :label="item.label" :value="item.id" :key="index"></option>
        </select>
        <el-button size="mini" style="margin-left: 10px" @click="reloadPage()">刷新</el-button>

      </div>
    </div>
    <div style="width: 100%;height: 100%;" v-if="includStr('智能')!=true">
      <div style="width: 100%;">
        <div class="container_class">
          <div class="containerItem">
            <BianZhi :bianZhiConfig="bianZhiConfig"/>
          </div>
          <div class="containerItem">
            <FengTou :fenTouConfig="fenTouConfig"/>
          </div>
          <div class="containerItem">
            <QuanJian :quanJianConfig="quanJianConfig"/>
          </div>
          <div class="containerItem">
            <DingXing :dingXingConfig="dingXingConfig"/>
          </div>
        </div>
      </div>
      <div
          style="color: #fff;width: 100%;display: flex;justify-content: space-around;align-items: center;">
        <div style="width: 29%">
          <pei-wa :pei-wa-config="peiWaConfig"/>
        </div>
        <div style="width: 29%">
          <BaoZhuang :baoZhuangConfig="baoZhuangConfig"/>
        </div>
        <div style="width: 39%;">
          <newChanLiangPaiHangBang :chan-liang-config="newChanLiangConfig"/>
          <!--          <Order :fengTouPaiHangBangList="fengTouPaiHangBangList" :quanJianPaiHangBangList="quanJianPaiHangBangList"-->
          <!--            :baoZhuangPaiHangBangList="baoZhuangPaiHangBangList" :dingXingPaiHangBangList="dingXingPaiHangBangList" />-->
        </div>
      </div>
    </div>


    <!--  智能线的时候  -->
    <div style="width: 100%;height: 100%;color: #ffffff;display: flex;justify-content: space-around"
         v-if="includStr('智能')">
      <div style="width: 24.5%;display: flex;flex-direction: column">
        <BianZhi :bianZhiConfig="bianZhiConfig"/>
        <newChanLiangPaiHangBang :chan-liang-config="newChanLiangConfig"/>
      </div>
      <div style="width: 24.5%">
        <DingXing :dingXingConfig="dingXingConfig"/>
      </div>
      <div style="width: 24.5%">
        <pei-wa :pei-wa-config="peiWaConfig"/>
      </div>
      <div style="width: 24.5%">
        <BaoZhuang :baoZhuangConfig="baoZhuangConfig"/>
      </div>
    </div>
  </dv-full-screen-container>
</template>
<script>
import BianZhi from './baoGong/bianZhi.vue'
import FengTou from './baoGong/fengTou.vue'
import QuanJian from './baoGong/quanJian.vue'
import DingXing from './baoGong/dingXing.vue'
import BaoZhuang from './baoGong/baoZhuang.vue'
// import Order from './baoGong/order.vue'
import newChanLiangPaiHangBang from './baoGong/newChanLiangPaiHangBang.vue'
import peiWa from "@/views/baoGong/peiWa.vue";

export default {
  components: {
    FengTou,
    QuanJian,
    DingXing,
    BaoZhuang,
    // Order,
    BianZhi,
    newChanLiangPaiHangBang,
    peiWa
  }, data() {
    return {
      tipLoading: true,//是否正在加载
      currentShuaXinPinLv: 0,//当前刷新频率
      dingShiQiTime: null,//定时器时间
      showLoaing: false,
      tableData: {},//表格数据
      dingShiQi: null,//定时器
      shengChanXianList: [],//生产线列表
      currentShengChanXian: '',//当前生产线
      fengTouPaiHangBangList: {},//报工数据集合
      quanJianPaiHangBangList: {},//全检产量排行榜数据
      dingXingPaiHangBangList: {},//定型产量排行数据
      baoZhuangPaiHangBangList: {},//包装产量排行数据
      fenTouConfig: {},//缝头数据集合
      dingXingConfig: {},//缝头数据集合
      baoZhuangConfig: {},//缝头数据集合
      quanJianConfig: {},//缝头数据集合
      bianZhiConfig: {},//编织数据集合
      newChanLiangConfig: {},//新的产量排行榜
      peiWaConfig: {},//配袜
      shuaXinPinLv: [{
        id: -1,
        label: '5秒',
        value: 1000 * 5
      },{
        id: 0,
        label: '30秒',
        value: 1000 * 30
      }, {
        id: 1,
        label: '1分钟',
        value: 1000 * 60 * 1
      }, {
        id: 2,
        label: '2分钟',
        value: 1000 * 60 * 2
      }, {
        id: 3,
        label: '3分钟',
        value: 1000 * 60 * 3
      }, {
        id: 4,
        label: '4分钟',
        value: 1000 * 60 * 4
      }, {
        id: 5,
        label: '5分钟',
        value: 1000 * 60 * 5
      },]
    }
  }, created() {
    this.getShengChanXian()
  }, methods: {
    selectHaoXian() {
      this.tableData = {}
      this.showLoaing = true
      //this.currentShengChanXian = val
      this.getData()
    },
    showDate() {
      // 创建一个 Date 对象
      const currentDate = new Date();

      // 获取当前日期的年、月、日
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1; // 月份从 0 开始，所以需要 +1
      if (month < 10) {
        month = "0" + month
      }
      let day = currentDate.getDate();
      if (day < 10) {
        day = "0" + day
      }
      return year + "-" + month + "-" + day
    },
    //查询生产线集合
    getShengChanXian() {
      var _this = this
      _this.$axios.get('/baoGongView/getHaoXianList').then(res => {
        _this.shengChanXianList = res.data
        _this.currentShengChanXian = res.data[0]
        _this.getData()
      })
    },
    getData() {
      //清除定时器
      if (this.dingShiQi != null) {
        clearInterval(this.dingShiQi)
        console.log("已清除定时器")
      }
      this.getShuaXinTime()
      var _this = this
      _this.$axios.get('/baoGongView/getData', {params: {haoXian: _this.currentShengChanXian}}).then(res => {
        console.log(res.data, "[[[[]]]]")
        _this.tableData = res.data
        _this.showLoaing = false
        // _this.zuZhuangOrderList(res.data.fengTouPaiHangBangList, "缝头")
        // _this.zuZhuangOrderList(res.data.quanJianPaiHangBangList, "全检")
        //
        // _this.zuZhuangOrderList(res.data.dingXingPaiHangBangList, "定型")
        // _this.zuZhuangOrderList(res.data.baoZhuangPaiHangBangList, "包装")


        _this.zuZhuangFengTouConfig(_this.tableData.fengTouList)
        _this.zuZhuangDingXingConfig(_this.tableData.dingXingList)
        _this.zuZhuangBaoZhuangConfig(_this.tableData.baoZhuangList)
        _this.zuZhuangQuanJianConfig(_this.tableData.quanJianList)
        _this.zuZhuangBianZhiConfig(_this.tableData.bianZhiList)

        _this.zuZhuangNewChanLiangConfig(_this.tableData.chanLiangPaiHangList)
        _this.zuZhuangPeiWaConfig(_this.tableData.peiWaList)
        _this.tipLoading = false
      })
      _this.dingShiQi = setInterval(function () {
        console.log("定时器时间", _this.dingShiQiTime)
        _this.tipLoading = true
        _this.$axios.get('/baoGongView/getData', {params: {haoXian: _this.currentShengChanXian}}).then(res => {
          console.log(res.data, "[[[[]]]]")
          // _this.zuZhuangOrderList(res.data.fengTouPaiHangBangList, "缝头")
          // _this.zuZhuangOrderList(res.data.quanJianPaiHangBangList, "全检")
          // _this.zuZhuangOrderList(res.data.dingXingPaiHangBangList, "定型")
          // _this.zuZhuangOrderList(res.data.baoZhuangPaiHangBangList, "包装")
          _this.tableData = res.data
          _this.zuZhuangFengTouConfig(_this.tableData.fengTouList)
          _this.zuZhuangDingXingConfig(_this.tableData.dingXingList)
          _this.zuZhuangBaoZhuangConfig(_this.tableData.baoZhuangList)
          _this.zuZhuangQuanJianConfig(_this.tableData.quanJianList)
          _this.zuZhuangBianZhiConfig(_this.tableData.bianZhiList)
          _this.zuZhuangNewChanLiangConfig(_this.tableData.chanLiangPaiHangList)
          _this.zuZhuangPeiWaConfig(_this.tableData.peiWaList)
          _this.tipLoading = false
        })
      }, _this.dingShiQiTime)
    },
    //组装订单列表
    zuZhuangOrderList(newValue, flag) {
      let newData = []
      this.orderList = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < newValue.length; i++) {
        let item = newValue[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      if (flag == '缝头') {
        this.fengTouPaiHangBangList = {...{}}
        this.fengTouPaiHangBangList = {
          ...{
            rowNum: 10,
            header: ['排名', '姓名', '折合产量'],
            align: ['center', 'center', 'center'],
            data: newData
          }
        }
      }
      if (flag == '全检') {
        this.quanJianPaiHangBangList = {...{}}
        this.quanJianPaiHangBangList = {
          ...{
            rowNum: 10,
            header: ['排名', '姓名', '折合产量'],
            align: ['center', 'center', 'center'],
            data: newData
          }
        }
      }
      if (flag == '定型') {
        this.dingXingPaiHangBangList = {...{}}
        this.dingXingPaiHangBangList = {
          ...{
            rowNum: 10,
            header: ['排名', '姓名', '折合产量'],
            align: ['center', 'center', 'center'],
            data: newData
          }
        }
      }
      if (flag == '包装') {
        this.baoZhuangPaiHangBangList = {...{}}
        this.baoZhuangPaiHangBangList = {
          ...{
            rowNum: 10,
            header: ['排名', '姓名', '折合产量'],
            align: ['center', 'center', 'center'],
            data: newData
          }
        }
      }
    }, zuZhuangFengTouConfig(list) {
      this.fenTouConfig = {...{}}
      let newData = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          console.log(Object.values(item)[j], "----")
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      this.fenTouConfig = {
        ...{
          rowNum: 10,
          header: ['姓名', '备注', '报工量', '折合产量'],
          data: newData,
          align: ['center', 'center', 'center', 'center']
        }
      }
    }, zuZhuangDingXingConfig(list) {
      this.dingXingConfig = {...{}}
      let newData = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      this.dingXingConfig = {
        ...{
          rowNum: 10,
          header: ['姓名', '备注', '报工量', '折合产量'],
          data: newData,
          align: ['center', 'center', 'center', 'center']
        }
      }
    },
    //新的产量
    zuZhuangNewChanLiangConfig(list) {
      this.newChanLiangConfig = {...{}}
      let newData = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      this.newChanLiangConfig = {
        ...{
          rowNum: 10,
          header: ['工序', '排名', '姓名', '折合产量'],
          data: newData,
          align: ['center', 'center', 'center', 'center']
        }
      }
    },
    //组装配袜数据
    zuZhuangPeiWaConfig(list) {
      this.peiWaConfig = {...{}}
      let newData = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      this.peiWaConfig = {
        ...{
          rowNum: 10,
          header: ['姓名', '备注', '报工量', '折合产量'],
          data: newData,
          align: ['center', 'center', 'center', 'center']
        }
      }
    }
    , zuZhuangBaoZhuangConfig(list) {
      this.baoZhuangConfig = {...{}}
      let newData = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      this.baoZhuangConfig = {
        ...{
          rowNum: 10,
          header: ['姓名', '备注', '报工量', '折合产量'],
          data: newData,
          align: ['center', 'center', 'center', 'center']
        }
      }
    }, zuZhuangQuanJianConfig(list) {
      this.quanJianConfig = {...{}}
      let newData = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      this.quanJianConfig = {
        ...{
          rowNum: 10,
          header: ['姓名', '备注', '报工量', '折合产量'],
          data: newData,
          align: ['center', 'center', 'center', 'center']
        }
      }
      console.log(this.quanJianConfig, "[[====]]")
    }, zuZhuangBianZhiConfig(list) {
      this.bianZhiConfig = {...{}}
      let newData = []
      // 在属性值发生变化时执行逻辑
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        let arrays = []
        for (let j = 0; j < Object.values(item).length; j++) {
          arrays.push(Object.values(item)[j])
        }
        newData.push(arrays)
      }
      this.bianZhiConfig = {
        ...{
          rowNum: 10,
          header: ['班次', '姓名', '报工量', '折合产量'],
          data: newData,
          align: ['center', 'center', 'center', 'center']
        }
      }
    },//刷新频率
    selectShuaXinPinLv() {
      //console.log(this.currentShuaXinPinLv,"--=============")
      //console.log(val, "****====================********--------")
      //this.currentShuaXinPinLv = val
      this.getData()
    },//获取刷新时间
    getShuaXinTime() {
      for (let i = 0; i < this.shuaXinPinLv.length; i++) {
        let item = this.shuaXinPinLv[i]
        if (item.id == this.currentShuaXinPinLv) {
          this.dingShiQiTime = item.value
          return
        }
      }
    },//获取刷新文字表述
    getShuaXinLabel(id) {
      for (let i = 0; i < this.shuaXinPinLv.length; i++) {
        let item = this.shuaXinPinLv[i]
        if (item.id == id) {
          return item.label
        }
      }
      return "-"
    },//刷新页面
    reloadPage() {
      location.reload(true)
    },//判断是否包含字符串
    includStr(val) {
      console.log(this.currentShengChanXian.includes(val), "jkhaklsd")
      return this.currentShengChanXian.includes(val)
    }
  }, beforeDestroy() {
    clearInterval(this.dingShiQi);
  }
}
</script>
<style scoped>
.da_pin_container {
  background-color: #3D3D57;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.container_class {
  display: flex;
  justify-content: space-around;
  height: 80%;
}

.containerItem {
  width: 24%;
}

.date_class {
  text-align: center;
  color: #fff;
  font-size: 0.35rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

::v-deep .ceil {
  font-size: 8px;
}
</style>
